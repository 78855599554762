.response_error_message {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: red;
  font-size: 16px;
  column-gap:5px;
}

.form_wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .form {
    display: flex;
    flex-direction: column;
    width: 300px;

    .error {
      color: red;
      margin: 5px 0;
    }

    .remember_wrapper {
      display: flex;
      align-items: center;
    }
  }
}