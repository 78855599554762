.loading_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 1000;
    border-radius: 10px;

    .loading_background{
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: .7;
        border-radius: 10px;
        padding: 5px;
    }

    .lds_dual_ring {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;

        &:after {
            content: " ";
            display: block;
            width: 64px;
            height: 64px;
            margin: 8px;
            border-radius: 50%;
            border: 32px solid #fff;
            border-color: #fff transparent #fff transparent;
            animation: lds-dual-ring 1.2s linear infinite;
        }
        @keyframes lds-dual-ring {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }

        .loading_image{
            position: absolute;
        }
    }
}

.error_wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100;
    top: 0;
    left: 0;
}

.not_found_wrapper{
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: var(--reportcomment);
    backdrop-filter: blur(10px);
}